<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <h1 class="primary--text text--lighten-1" :class="{'h1-sm': $vuetify.breakpoint.xs}">{{ property.name }}</h1>
        </v-row>
        <v-row align="center" justify="center">
          <h1 class="primary--text text--lighten-1" :class="{'h1-sm': $vuetify.breakpoint.xs}">
            in {{ property.address.city }}, {{ property.address.state }}
          </h1>
        </v-row>
        <v-row align="center" justify="center">
          <h2 class="primary--text" :class="{'h2-sm': $vuetify.breakpoint.xs}">
            2 Bedroom, 100% Free Utilities
          </h2>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="5">
        <div v-html="propertyDetails"></div>
      </v-col>
      <v-col cols="12" sm="12" md="7" class="px-5">
        <v-row>
          <v-col v-for="(image, i) in itemsPage" :key="i" cols="4" style="padding: 2px">
            <v-card flat tile class="d-flex rounded-card">
              <v-img :src="image.url"
                :lazy-src="image.url" aspect-ratio="1" class="grey lighten-2" @click="imageClicked(`${i}`)">
                <template v-slot:placeholder>
                  <v-layout fill-height align-center justify-center ma-0>
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-layout>
                </template>
                <div class="lightbox-images-more" v-if="i === itemsPage.length - 1 && itemsPage.length < items.length">
                  <v-layout fill-height align-center justify-center ma-0>
                    View More
                  </v-layout>
                </div>
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PropertyDetailsContent",

  props: {
    property: {
      type: Object,
      required: true,
    },
    propertyDetails: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  computed: {
    itemsPage() {
      return this.$store.getters.lightboxImages.slice(0, 6);
    },
  },

  methods: {
    imageClicked(i) {
      this.$emit("show", i);
    },
  },
};
</script>

<style scoped>
.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.lightbox {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.rounded-card {
  border-radius: 5px;
}

.lightbox-image img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.lightbox-height {
  height: 100%;
  cursor: pointer;
}

.lightbox-nav {
  position: absolute;
  z-index: 999;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}

.lightbox-page {
  color: white;
  font-size: 1em;
  width: 100%;
  position: absolute;
  z-index: 999;
}

.lightbox-close-button {
  color: white;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}

.lightbox-line1 {
  color: white;
  font-size: 1.2em;
  width: 100%;
  position: absolute;
  z-index: 999;
}

.lightbox-line2 {
  color: white;
  font-size: 1em;
  width: 100%;
  position: absolute;
  z-index: 999;
}

.lightbox-line3 {
  color: white;
  font-size: 1em;
  width: 100%;
  position: absolute;
  z-index: 999;
}

.lightbox-images-more {
  color: white;
  font-size: 1.2em;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
}
</style>